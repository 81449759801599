import React, { type PropsWithChildren } from 'react';
import { useUser } from './userProvider';
import { RealTimePnL, RealTimePositions, RealTimeMarketData, useUserAccount } from '@arlequin-finance/af-frontend-sdk';

export const RealTime = (props: PropsWithChildren): JSX.Element => {
  const { subaccountId } = useUser();
  const userAccount = useUserAccount(subaccountId ?? '');
  const accountKey = userAccount?.subaccountEncryptedId ?? '';

  return (
    <>
      {props.children}
      {subaccountId && (
        <>
          <RealTimePnL subAccountId={subaccountId.toString()} accountKey={accountKey} />
          <RealTimeMarketData />
          <RealTimePositions
            subAccountId={subaccountId.toString()}
            notification={() => { }}
          />
        </>
      )}
    </>
  );
};
