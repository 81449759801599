import React from 'react';
import { queryClient as sdkQueryClient } from '@arlequin-finance/af-frontend-sdk';
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import { QueryClient } from '@tanstack/react-query';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import type { PropsWithChildren } from 'react';

const queryClient = new QueryClient();
sdkQueryClient.set(queryClient);

const persister = createSyncStoragePersister({
  storage: window.localStorage,
});

export default function TanstackQuery(props: PropsWithChildren): JSX.Element {
  return (
    <PersistQueryClientProvider
      client={sdkQueryClient.get}
      persistOptions={{
        persister,
      }}
    >
      {props.children}
    </PersistQueryClientProvider>
  );
}
