/* eslint-disable @typescript-eslint/no-shadow */
import { useEffect, useMemo, useState } from 'react';
import { CIQ } from 'chartiq';
import { type UnderlyingId, usePrice, type UnderlyingSource } from '@arlequin-finance/af-frontend-sdk';
import nameValueStore from './nameValueStore';
import { type QuoteFeed } from './quoteFeed';
import { useChartTheme } from '../Providers/Theme';

interface ChartRealTimeDataProps {
  chartRef: any
  quoteFeed: QuoteFeed
  underlyingId: UnderlyingId
  underlyingSource: UnderlyingSource
}

export default function ChartRealTimeData(
  props: ChartRealTimeDataProps,
): null {
  const { chartRef, underlyingId, underlyingSource, quoteFeed } = props;
  const [theme, setTheme] = useState(localStorage.getItem('theme'));
  const THEME = useChartTheme();

  const uiContext = chartRef?.uiContext;
  const stx = chartRef?.stx;

  const themeHelper = useMemo(
    () => new (CIQ as any).ThemeHelper({ stx }),
    [],
  );

  const price = usePrice(underlyingId, underlyingSource);

  const sendRealTimePrice = (): void => {
    if (price?.last == null) return;
    if (price?.last_t == null) return;

    if (stx != null) {
      stx.streamParameters.maxTicks = 0;
      stx.streamParameters.maxWait = 0;
    }

    quoteFeed.realTimePriceUpdate(uiContext, {
      price: price.last,
      bid: price.bid,
      ask: price.ask,
      time: price.last_t,
      volume: price.vol ?? undefined,
    });
  };

  const sendRealTimeSpread = (): void => {
    try {
      if (stx == null) throw new Error('stx is null');
      if (price == null) throw new Error('price is null');

      if (price.bid_t == null) return;
      if (price.ask_t == null) return;
      if (price.ask == null) return;
      if (price.bid == null) return;

      const bidName = `bid${underlyingId}_${underlyingSource}`;
      const askName = `ask${underlyingId}_${underlyingSource}`;

      stx.removeSeries(bidName);
      stx.removeSeries(askName);

      const options = {
        shareYAxis: true,
        useChartLegend: false,
        highlightable: false,
        symbolObject: {
          exchange: '',
          underlyingId,
          underlyingSource,
          isBA: true,
        },
      };

      stx.addSeries('Bid', {
        ...options,
        color: '#eb403400',
        symbolObject: {
          ...options.symbolObject,
          symbol: bidName,
          name: 'Bid',
        },
        data: [
          {
            DT: quoteFeed.formatDate(
              new Date(price.bid_t ?? 0),
              quoteFeed.granularity,
            ),
            Value: price?.bid,
          },
        ],
      });

      stx.addSeries('Ask', {
        ...options,
        color: '#1bcc4d00',
        symbolObject: {
          ...options.symbolObject,
          symbol: askName,
          name: 'Ask',
        },
        data: [
          {
            DT: quoteFeed.formatDate(
              new Date(price?.ask_t ?? 0),
              quoteFeed.granularity,
            ),
            Value: price?.ask,
          },
        ],
      });
    } catch (error) {
      console.error('error add series bid ask', error);
    }
  };

  const setChartTheme = (): void => {
    if (THEME == null) return;
    if (stx == null) return;
    if (themeHelper == null) return;

    const currentChartTheme = nameValueStore.get(
      'themes__core-chartCIQ.Themes.prototype.current',
      () => {},
    );

    if (
      (currentChartTheme && currentChartTheme.theme == 'arlequin') ||
      JSON.stringify(currentChartTheme) === '{}'
    ) {
      themeHelper.settings = THEME;
    }

    themeHelper.update();
  };

  useEffect(() => {
    sendRealTimeSpread();
    sendRealTimePrice();
  }, [
    chartRef,
    underlyingId,
    underlyingSource,
    price?.last,
    price?.ask,
    price?.bid,
  ]);

  useEffect(() => {
    const getTheme = (): void => { setTheme(localStorage.getItem('theme') ?? ''); };
    window.addEventListener('themeChanged', getTheme);
    return () => {
      window.removeEventListener('themeChanged', getTheme);
    };
  }, []);

  useEffect(() => {
    setChartTheme();
  }, [theme, THEME]);

  useEffect(() => {
    setTimeout(() => { setChartTheme(); }, 100);
    setTimeout(() => { setChartTheme(); }, 500);
    setTimeout(() => { setChartTheme(); }, 1000);
    setTimeout(() => { setChartTheme(); }, 5000);
  }, []);

  return null;
}
