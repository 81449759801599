import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useUser } from './Providers/userProvider';
import Chart from '@chartiq/react-components/Chart/Advanced';
import initialConfig from './ChartIQ/config';
import { QuoteFeed } from './ChartIQ/quoteFeed';
import 'chartiq/plugins/tfc/tfc-loader';
import 'chartiq/plugins/tfc/tfc';
import './ChartIQ/advanced.scss';
import { useQueryClient } from '@tanstack/react-query';
import { useInstrument, useUserAccounts } from '@arlequin-finance/af-frontend-sdk';
import ChartObserver from './ChartIQ/ChartObserver';
import ChartRealTimeData from './ChartIQ/ChartRealTimeData';

function App(): JSX.Element {
  const user = useUser();
  localStorage.clear();

  const queryClient = useQueryClient();
  const chartRef = useRef<any>(null);
  const [chartLoaded, setChartLoaded] = useState(false);
  const [isChartReady, setIsChartReady] = useState<boolean>(false);

  useUserAccounts();
  useInstrument(user.product?.underlyingId ?? '', user.product?.underlyingSource ?? 'arq');

  const quoteFeed = useMemo(() => new QuoteFeed(), []);

  const chartConfig = useMemo(() => {
    return {
      ...initialConfig,
      initialSymbol: {
        symbol: user.product?.instrument?.symbol ?? user.product?.instrument?.shortName ?? user.product?.instrument?.fullName ?? 'AAPL',
        name: user.product?.instrument?.shortName ?? user.product?.instrument?.fullName ?? user.product?.instrument?.symbol ?? 'AAPL',
        exchDisp: '',
        underlyingId: user.product?.underlyingId ?? '',
        underlyingSource: user.product?.underlyingSource ?? '',
        isBA: false,
      },
    };
  }, [
    queryClient,
    user.product?.instrument?.fullName,
    user.product?.instrument?.shortName,
    user.product?.instrument?.symbol,
    user.product?.underlyingId,
    user.product?.underlyingSource,
    user.subaccountId,
  ]);

  useEffect(() => {
    let interval: NodeJS.Timer;
    if (!isChartReady) {
      interval = setInterval(() => {
        const chart = document.querySelectorAll('cq-context');
        if (chart.length > 0 && !isChartReady) {
          setIsChartReady(true);
          (window as any).ReactNativeWebView?.postMessage('loaded');
        }
      }, 100);
    }
    return () => {
      clearInterval(interval);
    };
  }, [isChartReady]);

  if (!user.product) return <div>Loading...</div>;

  return (
    <div className="AdvancedChart">
      <Chart
        ref={chartRef}
        config={chartConfig}
        resources={{
          quoteFeed,
          refreshInterval: 0,
        }}
        chartInitialized={() => { setChartLoaded(true); }}
      />
      {chartLoaded && user.product.underlyingSource && user.product.underlyingId && user.subaccountId && (
        <>
          <ChartRealTimeData
            chartRef={chartRef.current}
            quoteFeed={quoteFeed}
            underlyingId={user.product.underlyingId}
            underlyingSource={user.product.underlyingSource}
          />
          <ChartObserver
            chartRef={chartRef.current}
            underlyingId={user.product.underlyingId}
            underlyingSource={user.product.underlyingSource}
          />
        </>
      )}
    </div>
  );
}

export default App;
