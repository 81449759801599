import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import ChartLoader from './Components/ChartLoader';
import { UserProvider } from './Providers/userProvider';
import { logLevel, sdk, queryClient as sdkQueryClient } from '@arlequin-finance/af-frontend-sdk';
import TanstackQuery from './Providers/query';
import Theme from './Providers/Theme';
import { RealTime } from './Providers/RealTime';
import { QueryClient } from '@tanstack/react-query';

import './global.scss';

const queryClient = new QueryClient();
sdkQueryClient.set(queryClient);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
sdk.logLevel = logLevel.DEBUG;

sdk.url = {
  CASH_OPS: process.env.REACT_APP_CASH_OPS ?? '',
  REPORT_MANAGER: process.env.REACT_APP_REPORT_MANAGER ?? '',
  TIME_SERIES_SERVER: process.env.REACT_APP_TIME_SERIES_SERVER ?? '',
  MARKET_DATA: process.env.REACT_APP_MARKET_DATA ?? '',
  MARKET_DATA_INFO: process.env.REACT_APP_MARKET_DATA_INFO ?? '',
  MARKET_DATA_WEBSOCKET: process.env.REACT_APP_MARKET_DATA_WEBSOCKET ?? '',
  TRADING_SERVER: process.env.REACT_APP_TRADING_SERVER ?? '',
  USER_MANAGEMENT: process.env.REACT_APP_USER_MANAGEMENT ?? '',
  WS_TRADING_SERVER: process.env.REACT_APP_WS_TRADING_SERVER ?? '',
  USER_MANAGEMENT_WEBSOCKET: process.env.REACT_APP_USER_MANAGEMENT_WEBSOCKET ?? '',
  GET_UPLOAD_CLIENT_PROFILE_PICTURES: process.env
    .REACT_APP_GET_UPLOAD_CLIENT_PROFILE_PICTURES ?? '',
};

root.render(
  <React.StrictMode>
    <UserProvider>
      <TanstackQuery>
          <RealTime>
            <Theme>
              <ChartLoader>
                <App />
              </ChartLoader>
            </Theme>
          </RealTime>
      </TanstackQuery>
    </UserProvider>
  </React.StrictMode>,
);
