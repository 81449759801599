import {
  type Granularity,
  getPriceHistory,
} from '@arlequin-finance/af-frontend-sdk';

const DECIMALS = 6;

class QuoteFeed {
  granularity: Granularity = '1h';

  formatGranularity = function (period: number, timeUnit: string): Granularity {
    switch (timeUnit) {
      case 'millisecond':
        return `${period}ms` as Granularity;
      case 'second':
        return `${period}s` as Granularity;
      case 'minute':
        if (period === 60) return '1h' as Granularity;
        if (period === 240) return '4h' as Granularity;
        if (period === 480) return '8h' as Granularity;
        return `${period}m` as Granularity;
      case 'hour':
        return `${period}h` as Granularity;
      case 'day':
        return `${period}d` as Granularity;
      case 'week':
        return `${period}w` as Granularity;
      case 'month':
        return `${period}M` as Granularity;
      default:
        return `${period}h` as Granularity;
    }
  };

  formatDate = function (date: Date, granularity: string): string {
    switch (granularity) {
      case '250ms':
        date.setMilliseconds(
          date.getMilliseconds() - (date.getMilliseconds() % 250),
        );
        return date.toISOString();
      case '1s':
        date.setMilliseconds(0);
        return date.toISOString();
      case '5s':
        date.setMilliseconds(0);
        date.setSeconds(date.getSeconds() - (date.getSeconds() % 5));
        return date.toISOString();
      case '10s':
        date.setMilliseconds(0);
        date.setSeconds(date.getSeconds() - (date.getSeconds() % 10));
        return date.toISOString();
      case '15s':
        date.setMilliseconds(0);
        date.setSeconds(date.getSeconds() - (date.getSeconds() % 15));
        return date.toISOString();
      case '30s':
        date.setMilliseconds(0);
        date.setSeconds(date.getSeconds() - (date.getSeconds() % 30));
        return date.toISOString();
      case '1m':
        date.setSeconds(0);
        date.setMilliseconds(0);
        return date.toISOString();
      case '5m':
        date.setMinutes(date.getMinutes() - (date.getMinutes() % 5));
        date.setSeconds(0);
        date.setMilliseconds(0);
        return date.toISOString();
      case '10m':
        date.setMinutes(date.getMinutes() - (date.getMinutes() % 10));
        date.setSeconds(0);
        date.setMilliseconds(0);
        return date.toISOString();
      case '15m':
        date.setMinutes(date.getMinutes() - (date.getMinutes() % 15));
        date.setSeconds(0);
        date.setMilliseconds(0);
        return date.toISOString();
      case '30m':
        date.setMinutes(date.getMinutes() - (date.getMinutes() % 30));
        date.setSeconds(0);
        date.setMilliseconds(0);
        return date.toISOString();
      case '1h':
        date.setMinutes(0);
        date.setSeconds(0);
        date.setMilliseconds(0);
        return date.toISOString();
      case '1d':
        return date.toISOString().split('T')[0];

      default:
        return date.toISOString();
    }
  };

  realTimePriceUpdate(
    uiContext: any,
    data: {
      time: string
      price: number
      volume?: number
      bid?: number
      ask?: number
    },
    series?: string,
  ): void {
    try {
      if (uiContext == null) return;
      if (data.time == null) return;
      if (data.price == null) return;

      const periodicity = uiContext?.stx?.getPeriodicity();

      const granularity = this.formatGranularity(
        periodicity.period * periodicity.interval,
        periodicity.timeUnit,
      );

      this.granularity = granularity;

      uiContext?.stx?.updateChartData(
        [
          {
            DT: this.formatDate(new Date(data.time), granularity),
            Value: Math.round(data.price * 10 ** DECIMALS) / 10 ** DECIMALS,
            Bid: data.bid,
            Ask: data.ask,
            Volume: data.volume,
          },
        ],
        null,
        {
          fillGaps: true,
          ...(series && { secondarySeries: series }),
        },
      );
    } catch (error) {
      console.error('Error realTimePriceUpdate', error);
    }
  }

  getHistoricalData(
    symbol: any,
    suggestedStartDate: any,
    suggestedEndDate: any,
    params: any,
    cb: any,
    minInterval = 1000 * 60 * 60 * 24,
  ): void {
    try {
      const periodicity = params.stx.getPeriodicity();

      const granularity = this.formatGranularity(
        periodicity.period * periodicity.interval,
        periodicity.timeUnit,
      );

      const endDate =
        suggestedEndDate.getTime() < new Date().getTime()
          ? suggestedEndDate
          : new Date();

      const startDate: Date = suggestedStartDate;
      if (minInterval) {
        const diff = endDate.getTime() - startDate.getTime();
        if (diff < minInterval) {
          startDate.setTime(endDate.getTime() - minInterval);
        }
      }

      getPriceHistory(
        params.symbolObject.underlyingId,
        params.symbolObject.underlyingSource,
        startDate,
        endDate,
        granularity,
      )
        .then((response: any) => {
          const quotes = response.map((item: any) => ({
            DT: item.time.toISOString(),
            Open:
              item.open != null
                ? Math.round(item.open * 10 ** DECIMALS) / 10 ** DECIMALS
                : undefined,
            High:
              item.high != null
                ? Math.round(item.high * 10 ** DECIMALS) / 10 ** DECIMALS
                : undefined,
            Low:
              item.low != null
                ? Math.round(item.low * 10 ** DECIMALS) / 10 ** DECIMALS
                : undefined,
            Close:
              item.close != null
                ? Math.round(item.close * 10 ** DECIMALS) / 10 ** DECIMALS
                : undefined,
            Volume: item.volume,
          }));

          quotes.sort((a: any, b: any) => {
            const dateA = new Date(a.DT).getTime();
            const dateB = new Date(b.DT).getTime();
            return dateA - dateB;
          });

          cb({
            quotes: [...quotes],
            attribution: { source: 'Arlequin', exchange: 'REAL-TIME' },
          });
        })
        .catch((error) => {
          console.error('Error getHistoricalData -> ', error);
        });
    } catch (error) {
      console.error('Error fetchInitialData -> ', error);
    }
  }

  fetchPaginationData(
    symbol: any,
    startDate: any,
    endDate: any,
    params: any,
    cb: any,
  ): void {
    if (params.symbolObject.isBA ?? false) return;
    this.getHistoricalData(
      symbol,
      startDate,
      endDate,
      params,
      cb,
      1000 * 60 * 60 * 24 * 7,
    );
  }

  fetchInitialData(
    symbol: any,
    suggestedStartDate: any,
    suggestedEndDate: any,
    params: any,
    cb: any,
  ): void {
    if (params.symbolObject.isBA ?? false) return;
    this.getHistoricalData(
      symbol,
      suggestedStartDate,
      suggestedEndDate,
      params,
      cb,
    );
  }
}

export { QuoteFeed };
